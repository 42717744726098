import React, { useRef } from "react";
import HTMLFlipBook from "react-pageflip";

import Brochure1 from "../images/Brochure1.webp";
import Brochure2 from "../images/Brochure2.webp";
import Brochure3 from "../images/Brochure3.webp";
import Brochure4 from "../images/Brochure4.webp";
import Brochure5 from "../images/Brochure5.webp";
import Brochure6 from "../images/Brochure6.webp";
import Brochure7 from "../images/Brochure7.webp";
import Brochure8 from "../images/Brochure8.webp";
import Brochure9 from "../images/Brochure9.webp";
import Brochure10 from "../images/Brochure10.webp";
import Brochure11 from "../images/Brochure11.webp";
import Brochure12 from "../images/Brochure12.webp";
import Brochure13 from "../images/Brochure13.webp";
import Brochure14 from "../images/Brochure14.webp";
import Brochure15 from "../images/Brochure15.webp";
import Brochure16 from "../images/Brochure16.webp";

import "./Brochure.css";
import { motion } from "framer-motion";
function Brochure() {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  const backremove = {
    hidden: { opacity: 1 },
    show: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };
  const book = useRef();
  return (
    <>
      <motion.div
        className="gkt-brochure-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="overlayofherosection">
          <motion.div
            className="backgroundwhitecolor"
            variants={backremove}
          ></motion.div>
        </div>
        <div className="gkt-brochur-container">
          <div className="backgroundimagebox"></div>
          <HTMLFlipBook
            width={500}
            height={500}
            maxShadowOpacity={0.5}
            mobileScrollSupport={true}
            ref={book}
          >
            <div className="mixblandpere"></div>
            <div className="demoPage" data-density="hard">
              <img src={Brochure1} />
            </div>
            <div className="demoPage">
              <img src={Brochure2} />
            </div>
            <div className="demoPage">
              <img src={Brochure3} />
            </div>
            <div className="demoPage">
              <img src={Brochure4} />
            </div>
            <div className="demoPage">
              <img src={Brochure7} />
            </div>
            <div className="demoPage">
              <img src={Brochure11} />
            </div>
            <div className="demoPage">
              <img src={Brochure12} />
            </div>
            <div className="demoPage">
              <img src={Brochure5} />
            </div>
            <div className="demoPage">
              <img src={Brochure6} />
            </div>
            <div className="demoPage">
              <img src={Brochure8} />
            </div>
            <div className="demoPage">
              <img src={Brochure9} />
            </div>
            <div className="demoPage">
              <img src={Brochure10} />
            </div>

            <div className="demoPage">
              <img src={Brochure13} />
            </div>
            <div className="demoPage">
              <img src={Brochure14} />
            </div>
            <div className="demoPage">
              <img src={Brochure15} />
            </div>
            <div className="demoPage">
              <img src={Brochure16} />
            </div>
          </HTMLFlipBook>
          <div className="navibuttons">
            <button onClick={() => book.current.pageFlip().flipPrev()}>
              previous page
            </button>
            <button onClick={() => book.current.pageFlip().flipNext()}>
              Next page
            </button>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default Brochure;
