import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";

import biggrpleaf from "../images/liaf-grp1.webp";
import leafblur6 from "../images/liaf-blur6.webp";
import leafblur1 from "../images/liaf-blur1.webp";
import leafgrp1 from "../images/leaf-grp1.webp";
import leafgrp5 from "../images/leaf-grp5.webp";
import leafgrp4 from "../images/leaf-grp4.webp";
import leafgrp3 from "../images/leaf-grp3.webp";
import footer2 from "../images/footer2.webp";
import leafgrp2 from "../images/leaf-grp2.webp";
import "./about.css";

function About() {
  const [offsetY, setOffsetY] = useState(0);
  const [triggersleepy, settriggersleepy] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 1540) {
      settriggersleepy(true);
    }
    if (window.scrollY < 1540) {
      settriggersleepy(false);
    }
    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div id="About" className="gkt-about-wrapper">
        <div className="gkt-about-box">
          <div className="gkt-aboutgrid-box">
            <div className="gkt-side-navigation">
              <p
                style={
                  triggersleepy
                    ? { transform: `translateY(0px)`, opacity: 1 }
                    : { transform: `translateY(50px)`, opacity: 0 }
                }
              >
                <HashLink smooth to="/#top" className="side-link">
                  Home
                </HashLink>
              </p>
              <p
                style={
                  triggersleepy
                    ? { transform: `translateY(0px)`, opacity: 1 }
                    : { transform: `translateY(50px)`, opacity: 0 }
                }
              >
                <HashLink smooth to="/#Product" className="side-link">
                  Product
                </HashLink>
              </p>
              <div
                className="gkt-varticle-line"
                style={
                  triggersleepy
                    ? { transform: `translateY(0px)`, opacity: 1 }
                    : { transform: `translateY(50px)`, opacity: 0 }
                }
              ></div>
              <p
                style={
                  triggersleepy
                    ? { transform: `translateY(0px)`, opacity: 1 }
                    : { transform: `translateY(50px)`, opacity: 0 }
                }
              >
                <HashLink smooth to="/#Benefits" className="side-link">
                  benefits
                </HashLink>
              </p>
              <p
                style={
                  triggersleepy
                    ? { transform: `translateY(0px)`, opacity: 1 }
                    : { transform: `translateY(50px)`, opacity: 0 }
                }
              >
                <HashLink smooth to="/Contact" className="side-link">
                  contact
                </HashLink>
              </p>
            </div>
            <div className="gkt-main-about-sec">
              <div className="gkt-main-about-heding">
                <div className="gkt-about-hedding-box">
                  <span
                    className="gkt-about-round"
                    style={{
                      transform: `scale(${offsetY * 0.0005}) translateX(50%)`,
                    }}
                  ></span>
                  <h1
                    style={{
                      transform: `scale(${offsetY * 0.0005})`,
                    }}
                  >
                    about us
                  </h1>
                  <div className="tea-leaf-forabout">
                    <img
                      src={biggrpleaf}
                      style={{
                        transform: `translateY(-${
                          offsetY * 0.002
                        }%) translateX(50%)`,
                      }}
                    />
                  </div>
                </div>
                <p
                  className="firstp"
                  style={{
                    transform: `translateX(-${offsetY * 0.005}%)`,
                  }}
                >
                  One can’t buy happiness, but I can buy a cup of “good quality”
                  tea, and that almost means the same! Here’s why, what’s that
                  one thing we need when the sun spreads his shine around us
                  after a dark night, when we are just about to head out for
                  another stressful work day, when we just want to sit on our
                  porch and spend some quality time with our loved ones, when we
                  have an important client meeting and don’t want to leave any
                  stone unturned to get through the deal, when we want to
                  hangout with our friends on a rainy day or when we want to
                  just kill the winter chills!
                </p>
                <p
                  className="secondp"
                  style={{
                    transform: `translateX(${offsetY * 0.005}%)`,
                  }}
                >
                  We at Gopal Krishna Tea Estate are now working to make these
                  thoughts alive; We strongly believe that “a good cup of tea is
                  all you need for you and me”! In our initiative to bring the
                  fresh tea from our plantations to your cups we have worked
                  with the sole objective to make this experience the most
                  enriching for you. With this with introduce to you our own
                  manufactured and packaged tea “GKT” with the sole aim of
                  making perfectly brewed cup of tea for the perfect you!
                </p>
                <span
                  style={{
                    fontSize: `${offsetY * 0.008}px`,
                  }}
                >
                  <HashLink smooth to="/About" className="auout_usbtn">
                    about us
                  </HashLink>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="gkt-abtealeaf-container">
          <div className="gkt-ableaf">
            <img
              src={leafblur6}
              className="position-absolute ableafblur6"
              style={{
                transform: `rotate(${offsetY * 0.06}deg) `,
              }}
            />
            <img
              src={leafblur1}
              className="position-absolute ableafblur1"
              style={{
                transform: `rotate(${offsetY * 0.06}deg) `,
              }}
            />
            <img
              src={leafgrp5}
              className="position-absolute ableafgrp5"
              style={{
                transform: `translateY(${offsetY * 0.08}px)  rotate(84deg)`,
              }}
            />
            <img
              src={leafgrp4}
              className="position-absolute ableafgrp4"
              style={{
                transform: `translateY(${offsetY * 0.08}px)  rotate(-1deg)`,
              }}
            />
            <img
              src={leafblur6}
              className="position-absolute ableafblur61"
              style={{
                transform: `rotate(${offsetY * 0.06}deg) `,
              }}
            />
            <img
              src={leafblur1}
              className="position-absolute ableafblur11"
              style={{
                transform: `rotate(${offsetY * 0.06}deg) `,
              }}
            />
            <img
              src={leafgrp5}
              className="position-absolute ableafgrp51"
              style={{
                transform: `translateY(${offsetY * 0.08}px)  rotate(99deg)`,
              }}
            />
            <img
              src={leafgrp3}
              className="position-absolute ableafgrp3"
              style={{
                transform: `translateY(${offsetY * 0.08}px)  rotate(${
                  offsetY * 0.02
                }deg)`,
              }}
            />
            <img
              src={leafgrp3}
              className="position-absolute ableafgrp31"
              style={{
                transform: `translateY(${offsetY * 0.04}px)  rotate(${
                  offsetY * 0.02
                }deg)`,
              }}
            />
            <img
              src={leafgrp4}
              className="position-absolute ableafgrp41"
              style={{
                transform: `translateY(${offsetY * 0.01}px)  rotate(-18deg)`,
              }}
            />
            <img
              src={leafgrp5}
              className="position-absolute ableafgrp52"
              style={{
                transform: `translateY(${offsetY * 0.09}px)  rotate(${
                  offsetY * 0.05
                }deg)`,
              }}
            />

            <img
              src={leafgrp3}
              className="position-absolute ableafgrp33"
              style={{
                transform: `translateY(${offsetY * 0.03}px)  rotate(-166deg)`,
              }}
            />
            <img
              src={leafgrp4}
              className="position-absolute ableafgrp42"
              style={{
                transform: `translateY(${offsetY * 0.1}px)  rotate(11deg)`,
              }}
            />
            <img
              src={leafblur1}
              className="position-absolute ableafblur12"
              style={{
                transform: `rotate(${offsetY * 0.06}deg) `,
              }}
            />
            <img
              src={footer2}
              className="position-absolute abfooter2"
              style={{
                transform: `translateY(${offsetY * 0.08}px)  rotate(72deg)`,
              }}
            />
            <img
              src={leafgrp2}
              className="position-absolute ableafgrp2"
              style={{
                transform: `translateY(${offsetY * 0.1}px)  rotate(${
                  offsetY * 0.06
                }deg)`,
              }}
            />
            <img
              src={leafgrp1}
              className="position-absolute ableafgrp11"
              style={{
                transform: `translateY(${offsetY * 0.1}px)  rotate(${
                  offsetY * 0.05
                }deg)`,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
