import React, { useState } from "react";
import logo from "../images/gktlogo.png";
import { NavLink, Link } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import { motion } from "framer-motion";

import "./navbar.css";
function Navbar() {
  const [Click, setClick] = useState(false);
  const handelClick = () => setClick(!Click);
  const closemibilemanu = () => setClick(false);

  const toptobottom = {
    hidden: { y: -80 },
    show: {
      y: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.6,
      },
    },
  };
  return (
    <>
      <motion.div
        id="top"
        className="gkt-navbar-wrapper"
        variants={toptobottom}
        initial="hidden"
        animate="show"
      >
        <div className="gkt-logo-container">
          <NavLink to="/">
            <img src={logo} className="gkt-logo" />
          </NavLink>
        </div>
        <div
          className={
            Click
              ? "gkt-links-container gkt-mobile-link"
              : "gkt-links-container"
          }
        >
          <ul className="gkt-nav-container">
            <li className="gkt-nav-item">
              <NavLink
                to="/"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive ? "active gkt-nav-link" : "gkt-nav-link"
                }
              >
                Home
              </NavLink>
            </li>
            <li className="gkt-nav-item">
              <NavLink
                to="/About"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive ? "active gkt-nav-link" : "gkt-nav-link"
                }
              >
                About us
              </NavLink>
            </li>
            <li className="gkt-nav-item">
              <HashLink
                to="/#Product"
                onClick={closemibilemanu}
                className="gkt-nav-link"
              >
                Product
              </HashLink>
            </li>
            <li className="gkt-nav-item">
              <HashLink
                to="/#Benefits"
                onClick={closemibilemanu}
                className="gkt-nav-link"
              >
                benefits
              </HashLink>
            </li>
            <li className="gkt-nav-item">
              <NavLink
                to="/Contact"
                onClick={closemibilemanu}
                className={({ isActive }) =>
                  isActive ? "active gkt-nav-link" : "gkt-nav-link"
                }
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="gkt-Gallery-container">
          <div className="gkt-btn-container">
            <NavLink to="/Brochure" className="gkt-galary-btn">
              Brochure
            </NavLink>
          </div>
        </div>
        <div className="gkt-hamburder-menu" onClick={handelClick}>
          <i className={Click ? "fa fa-2x fa-times" : "fa fa-2x fa-bars"} />
        </div>
      </motion.div>
    </>
  );
}

export default Navbar;
