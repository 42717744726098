import React, { useState, useEffect } from "react";
import biggrpleaf from "../images/liaf-grp1.webp";
import teacup from "../images/teacup.webp";

import { HashLink } from "react-router-hash-link";

import leafblur6 from "../images/liaf-blur6.webp";
import leafblur1 from "../images/liaf-blur1.webp";
import leafblur3 from "../images/liaf-blur3.webp";
import leafgrp5 from "../images/leaf-grp5.webp";
import leafgrp3 from "../images/leaf-grp3.webp";
import leafgrp2 from "../images/leaf-grp2.webp";
import "./benefits.css";

function Benefits() {
  const [offsetY, setOffsetY] = useState(0);
  const [trigger, settrigger] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 2340) {
      settrigger(true);
    }
    if (window.scrollY < 2340) {
      settrigger(false);
    }

    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div id="Benefits" className="gkt-Benefits-wrapper">
        <div className="gkt-Benefits-box">
          <div className="gkt-Benefitsgrid-box">
            <div
              className="gkt-side-navigation"
              style={
                trigger
                  ? { transform: `translateY(0px)`, opacity: 1 }
                  : { transform: `translateY(50px)`, opacity: 0 }
              }
            >
              <p>
                <HashLink smooth to="/#top" className="side-link">
                  Home
                </HashLink>
              </p>
              <p>
                <HashLink smooth to="/#Product" className="side-link">
                  Product
                </HashLink>
              </p>
              <p>
                <HashLink smooth to="/#About" className="side-link">
                  about us
                </HashLink>
              </p>
              <div className="gkt-varticle-line"></div>
              <p>
                <HashLink smooth to="/Contact" className="side-link">
                  contact
                </HashLink>
              </p>
            </div>
            <div className="gkt-main-Benefits-sec">
              <div className="gkt-main-Benefits">
                <div className="gkt-Benefits-colorbox">
                  <div className="gkt-banefits-detailes">
                    <h3
                      style={{
                        transform: `scale(${offsetY * 0.0004})`,
                      }}
                    >
                      benefits
                    </h3>
                    <p
                      style={{
                        bottom: `${offsetY * 0.004}%`,
                      }}
                    >
                      a sip of natural tea gives plethora of benefits.
                    </p>
                  </div>
                  <div className="gkt-benefits-grid">
                    <div className="gkt-benifits">
                      <div className="gkt-benifits-inner">
                        <i
                          className="fa fa-3x fa-pagelines"
                          style={{
                            fontSize: `${offsetY * 0.02}px`,
                          }}
                        ></i>
                        <h4>100% Pure</h4>
                        <p
                          style={{
                            lineHeight: `${offsetY * 0.00055}`,
                          }}
                        >
                          Our tea is produced through 100% natural process from
                          finest green tea leaves to make it full of
                          anti-oxidants and naturally pure.
                        </p>
                      </div>
                    </div>
                    <div className="gkt-benifits">
                      <div className="gkt-benifits-inner">
                        <i
                          className="fa fa-3x fa-coffee"
                          style={{
                            fontSize: `${offsetY * 0.02}px`,
                          }}
                        ></i>
                        <h4>Healthy & Hygienic</h4>
                        <p
                          style={{
                            lineHeight: `${offsetY * 0.00055}`,
                          }}
                        >
                          We are considered to be one of the most hygienic and
                          well-equipped tea manufacturing Tea Estate and produce
                          quality tea in the most hygienic conditions.
                        </p>
                      </div>
                    </div>
                    <div className="gkt-benifits">
                      <div className="gkt-benifits-inner">
                        <i
                          className="fa fa-3x fa-heartbeat"
                          style={{
                            fontSize: `${offsetY * 0.02}px`,
                          }}
                        ></i>
                        <h4>Quality Guranteed</h4>
                        <p
                          style={{
                            lineHeight: `${offsetY * 0.00055}`,
                          }}
                        >
                          Our teas are hand-plucked and hand-picked again by our
                          master tea tasters to ensure only the best are
                          selected for your cup.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  src={teacup}
                  className="gkt-teacup-img"
                  style={{
                    transform: `rotate(${offsetY * 0.08}deg) `,
                  }}
                />
              </div>
              <img
                src={biggrpleaf}
                className="gkt-tealeaf-img"
                style={{
                  left: `-${offsetY * 0.003}%`,
                }}
              />
            </div>
          </div>
        </div>
        <div className="gkt-bnitealeaf-container">
          <div className="gkt-bnileaf">
            <img
              src={leafblur6}
              className="position-absolute bnileafblur6"
              style={{
                transform: `rotate(-${offsetY * 0.06}deg) translateY(-${
                  offsetY * 0.02
                }px)`,
              }}
            />
            <img
              src={leafblur1}
              className="position-absolute bnileafblur1"
              style={{
                transform: `rotate(${offsetY * 0.07}deg) translateY(${
                  offsetY * 0.05
                }px)`,
              }}
            />
            <img
              src={leafgrp5}
              className="position-absolute bnileafgrp5"
              style={{
                transform: `rotate(${offsetY * 0.08}deg) `,
              }}
            />
            <img
              src={leafgrp3}
              className="position-absolute bnileafgrp3"
              style={{
                transform: `rotate(${offsetY * 0.03}deg) `,
              }}
            />
            <img
              src={leafgrp3}
              className="position-absolute bnileafgrp31"
              style={{
                transform: `rotate(${offsetY * 0.09}deg) `,
              }}
            />
            <img
              src={leafgrp2}
              className="position-absolute bnileafgrp2"
              style={{
                transform: `rotate(-${offsetY * 0.06}deg) `,
              }}
            />
            <img
              src={leafgrp2}
              className="position-absolute bnileafgrp21"
              style={{
                transform: `rotate(${offsetY * 0.04}deg) `,
              }}
            />
            <img
              src={leafblur6}
              className="position-absolute bnileafblur61"
              style={{
                transform: `rotate(${offsetY * 0.08}deg) `,
              }}
            />
            <img
              src={leafblur1}
              className="position-absolute bnileafblur11"
              style={{
                transform: `rotate(${offsetY * 0.05}deg) `,
              }}
            />
            <img
              src={leafblur3}
              className="position-absolute bnileafblur3"
              style={{
                transform: `rotate(${offsetY * 0.04}deg) `,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Benefits;
