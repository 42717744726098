import React, { useState, useEffect } from "react";
import "./herosection.css";
import background from "../images/heroimage1.webp";
import cloud from "../images/cloud.webp";
import cloud1 from "../images/cloud2.webp";
import leafblur1 from "../images/liaf-blur1.webp";
import leafblur2 from "../images/liaf-blur2.webp";
import leafblur3 from "../images/liaf-blur3.webp";
import leafblur4 from "../images/liaf-blur4.webp";
import leafblur6 from "../images/liaf-blur6.webp";
import leafgrp3 from "../images/leaf-grp3.webp";
import leafgrp1 from "../images/leaf-grp1.webp";
import leafgrp2 from "../images/leaf-grp2.webp";
import { motion } from "framer-motion";
function Herosection() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const items = {
    hidden: { opacity: 0, x: 200 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1.6,
      },
    },
  };
  const cloudmain = {
    hidden: { y: 300 },
    show: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const toptobottom = {
    hidden: { y: -80 },
    show: {
      y: 500,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 2,
      },
    },
  };
  const bottomtotop = {
    hidden: { y: 80, opacity: 1, rotate: 180 },
    show: {
      y: -500,
      opacity: 1,
      rotate: 180,
      transition: {
        ease: [0.6, 0.99, 0.99, 0.95],
        duration: 2,
      },
    },
  };
  const rightcloud = {
    hidden: { x: 200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const leftcloud = {
    hidden: { x: -200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const backremove = {
    hidden: { opacity: 1 },
    show: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const leaftop = {
    hidden: { opacity: 0, y: -200 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  const leafbottom = {
    hidden: { opacity: 0, y: 200 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };

  return (
    <>
      <motion.section
        id="herosection"
        className="gkt-herosection-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="overlayofherosection">
          <motion.img src={cloud} className="topcloud" variants={toptobottom} />
          <motion.img
            src={cloud}
            className="bottomcloud"
            variants={bottomtotop}
          />
          <motion.div
            className="backgroundwhitecolor"
            variants={backremove}
          ></motion.div>
        </div>
        <div className="gkt-hero-container">
          <div className="gkt-image-container">
            <div className="gkt-hero-text-continer">
              <div
                className="gkt-hero-text"
                style={{ transform: `translateY(${offsetY * 0.3}px)` }}
              >
                <motion.h1 className="gkt-hero-h1text" variants={items}>
                  premium quality <br />
                  assam tea
                </motion.h1>
                <motion.p className="gkt-hero-ptext" variants={items}>
                  straight from the Garden of Assam
                </motion.p>
              </div>
            </div>
            <div className="gkt-bottomwhite-overlay"></div>
            <div className="gkt-cloud-overlay">
              <motion.div variants={cloudmain} className="cloudegrpbottom">
                <img
                  src={cloud}
                  style={{
                    transform: `translateY(-${offsetY * 0.2}px) `,
                  }}
                />
              </motion.div>
              <motion.div variants={rightcloud} className="solocloud">
                <img
                  src={cloud1}
                  style={{
                    transform: `translateX(-${offsetY * 0.4}px) `,
                  }}
                />
              </motion.div>
              <motion.div variants={leftcloud} className="solocloud1">
                <motion.img
                  src={cloud1}
                  style={{
                    transform: `translateX(${offsetY * 0.4}px)  rotate(25deg)`,
                  }}
                />
              </motion.div>
            </div>
            <div className="gkt-tealeaf-container">
              <div className="gkt-leaf">
                <motion.div
                  className="aboutuslead position-absolute leafblur6"
                  variants={leaftop}
                >
                  <img
                    src={leafblur6}
                    style={{
                      transform: `rotate(${offsetY * 0.1}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafblur1"
                  variants={leaftop}
                >
                  <img
                    src={leafblur1}
                    style={{
                      transform: `rotate(${offsetY * 0.1}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafblur3"
                  variants={leaftop}
                >
                  <img
                    src={leafblur3}
                    style={{
                      transform: `rotate(${offsetY * 0.08}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafblur4"
                  variants={leaftop}
                >
                  <img
                    src={leafblur4}
                    style={{
                      transform: `rotate(${offsetY * 0.08}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafgrp3"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp3}
                    style={{
                      transform: `rotate(${offsetY * 0.08}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafgrp31"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp3}
                    style={{
                      transform: `rotate(${offsetY * 0.08}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafgrp1"
                  variants={leafbottom}
                >
                  <img
                    src={leafgrp1}
                    style={{
                      transform: `rotate(${offsetY * 0.03}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafgrp2"
                  variants={leafbottom}
                >
                  <img
                    src={leafgrp2}
                    style={{
                      transform: `rotate(${offsetY * 0.08}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafblur31"
                  variants={leafbottom}
                >
                  <img
                    src={leafblur3}
                    style={{
                      transform: `rotate(${offsetY * 0.08}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafblur2"
                  variants={leafbottom}
                >
                  <img
                    src={leafblur2}
                    style={{
                      transform: `rotate(${offsetY * 0.08}deg) `,
                    }}
                  />
                </motion.div>
              </div>
            </div>
            {/* <div className="gkt-topwhite-overlay"></div>*/}

            <img
              src={background}
              className="backgroundheroimage"
              style={{ transform: `translateY(-${offsetY * 0.3}px)` }}
            />
          </div>
        </div>
      </motion.section>
    </>
  );
}

export default Herosection;
