import React, { useState, useEffect } from "react";
import background from "../images/heroimage2.webp";
import catly from "../images/catly.webp";
import cloud from "../images/cloud.webp";
import cloud1 from "../images/cloud2.webp";
import leafblur6 from "../images/liaf-blur6.webp";
import leafblur1 from "../images/liaf-blur1.webp";
import leafgrp1 from "../images/leaf-grp4.webp";
import leafgrp4 from "../images/leaf-grp1.webp";
import "./tea_info.css";

function Teainfo() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="gkt-teainfo-wrapper">
        <div className="gkt-backgroundimage-container">
          <div className="gkt-teainfo-gridsec">
            <div
              className="gkt-info-container"
              style={{ transform: `translateX(${offsetY * 0.2}px)` }}
            >
              <h1>Always Fresh</h1>
              <p>
                Freshly picked raw tea leaves are processed and packed to ensure
                your tea is always fresh and contains all natural nutrients.
              </p>
            </div>
            <div className="gkt-teacup-img">
              <img
                src={catly}
                className="teacup-img"
                style={{ transform: `translateX(-${offsetY * 0.2}px)` }}
              />
            </div>
            <div className="gkt-cloud-teainfo-overlay">
              <img src={cloud} className="teainfo-cloudegrpbottom" />
              <img
                src={cloud1}
                className="teainfo-solocloud"
                style={{ transform: `translateX(-${offsetY * 0.1}px)` }}
              />
            </div>
            <div className="gkt-infotealeaf-container">
              <div className="gkt-infoleaf">
                <img
                  src={leafblur6}
                  className="position-absolute infoleafblur6"
                  style={{
                    transform: `rotate(${offsetY * 0.03}deg) `,
                  }}
                />
                <img
                  src={leafblur1}
                  className="position-absolute infoleafblur1"
                  style={{
                    transform: `rotate(${offsetY * 0.06}deg) `,
                  }}
                />
                <img
                  src={leafgrp1}
                  className="position-absolute infoleafgrp1"
                  style={{
                    transform: `translateY(${
                      offsetY * 0.08
                    }px)  rotate(-125deg)`,
                  }}
                />
                <img
                  src={leafgrp4}
                  className="position-absolute infoleafgrp4"
                  style={{
                    transform: `rotate(${offsetY * 0.015}deg) `,
                  }}
                />
              </div>
            </div>
            <img
              src={background}
              className="backgroundimage"
              style={{ transform: `scaleY(${(offsetY * 0.0017) / 2})` }}
            />
          </div>
          <div className="gkt-topwhite-overlay"></div>
          <div className="gkt-bottomwhite-teainfo-overlay"></div>
        </div>
      </div>
    </>
  );
}

export default Teainfo;
