import React from "react";
import "./footer.css";
import footer1 from "../images/footer1.webp";
import footer2 from "../images/footer2.webp";
import logo from "../images/gktlogo.png";

function Footer() {
  return (
    <>
      <div className="gkt-footer-wrapper">
        <div className="gkt-background-img">
          <div className="gkt-footer-detaile-box">
            <div className="gkt-top-detailes">
              <div className="gkt-left-side">
                <img src={logo} className="gkt-logo" />
              </div>
              <div className="gkt-right-seid">
                <p>
                  We deliver the finest tea from Gopal Krishna tea gardens in
                  Assam producing around 1 million kg of tea annually. We
                  produce premium quality black CTC and orthodox teas.
                </p>
              </div>
            </div>
            <div className="gkt-bottom-detailes">
              <div className="gkt-left-side">
                <p>
                  Copyright © {new Date().getFullYear()}, All rights reserved By
                  gopal krishna tea
                </p>
              </div>
              <div className="gkt-right-seid">
                <a href="https://www.facebook.com/gktghy/" target="_blank">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a
                  href="https://instagram.com/gopalkrishnatea?utm_medium=copy_link"
                  target="_blank"
                >
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <img src={footer1} className="foote1" />
          <img src={footer2} className="foote2" />
          {/*       <img src={footer3} className="foote3" /> */}
        </div>
      </div>
    </>
  );
}

export default Footer;
