import React, { useState, useEffect } from "react";
import "./about_us.css";

import cloud from "../images/cloud.webp";
import cloud1 from "../images/cloud2.webp";

import leafblur6 from "../images/liaf-blur6.webp";
import leafgrp3 from "../images/leaf-grp3.webp";
import leafgrp2 from "../images/leaf-grp2.webp";
import leafblur1 from "../images/liaf-blur1.webp";
import { motion } from "framer-motion";
import Footer from "./footer";
function About_us() {
  const [offsetY, setOffsetY] = useState(0);
  const [trigger, settrigger] = useState(false);
  const [triggersleepy, settriggersleepy] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 300) {
      settrigger(true);
    }
    if (window.scrollY < 350) {
      settrigger(false);
    }
    if (window.scrollY > 900) {
      settriggersleepy(true);
    }
    if (window.scrollY < 900) {
      settriggersleepy(false);
    }
    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  const backremove = {
    hidden: { opacity: 1 },
    show: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };
  const items = {
    hidden: { opacity: 0, x: 200 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1.6,
      },
    },
  };
  const rightcloud = {
    hidden: { x: 200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const leftcloud = {
    hidden: { x: -200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const cloudmain = {
    hidden: { y: 300 },
    show: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const cloudmaintop = {
    hidden: { y: -300, rotate: 180 },
    show: {
      y: 0,
      rotate: 180,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const leaftop = {
    hidden: { opacity: 0, y: -200 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  return (
    <>
      <motion.section
        className="gkt-aboutus-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="overlayofherosection">
          <motion.div
            className="backgroundwhitecolor"
            variants={backremove}
          ></motion.div>
        </div>
        <div className="gkt-aboutus-container">
          <div className="gkt-aboutebackimg-container">
            <div className="gkt-aboutus-text-continer">
              <div
                className="gkt-aboutus-text"
                style={{ transform: `translateY(${offsetY * 0.3}px)` }}
              >
                <motion.h1 className="gkt-aboutus-h1text" variants={items}>
                  About Gopal Krishna Tea
                </motion.h1>
                <motion.p variants={items}>
                  straight from the Garden of Assam
                </motion.p>
              </div>
            </div>
            <div className="gkt-aboutus-cloud-overlay">
              <motion.div className="cloudegrpbottom" variants={cloudmain}>
                <img
                  src={cloud}
                  style={{
                    transform: `translateY(-${offsetY * 0.2}px) `,
                  }}
                />
              </motion.div>
              <motion.div className="cloudegrpbottom1" variants={cloudmaintop}>
                <img src={cloud} />
              </motion.div>
              <motion.div className="solocloud" variants={rightcloud}>
                <img
                  src={cloud1}
                  style={{
                    transform: `translateX(${offsetY * 0.5}px) `,
                  }}
                />
              </motion.div>
              <motion.div className="solocloud1" variants={leftcloud}>
                <img
                  src={cloud1}
                  className=""
                  style={{
                    transform: `translateX(-${offsetY * 0.2}px) `,
                  }}
                />
              </motion.div>
            </div>
            <div className="gkt-aboutustealeaf-container">
              <div className="gkt-aboutusleaf">
                <motion.div
                  className="aboutuslead position-absolute aboutusleafblur6"
                  variants={leaftop}
                >
                  <img
                    src={leafblur6}
                    style={{
                      transform: `rotate(${offsetY * 0.1}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute aboutusleafgrp3"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp3}
                    style={{
                      transform: `translateY(${
                        offsetY * 0.2
                      }px) rotate(332deg)`,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute aboutusleafgrp2"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp2}
                    style={{
                      transform: `rotate(${offsetY * 0.1}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute aboutusleafblur1"
                  variants={leaftop}
                >
                  <img
                    src={leafblur1}
                    style={{
                      transform: `rotate(${offsetY * 0.08}deg) translateY(${
                        offsetY * 0.3
                      }px)`,
                    }}
                  />
                </motion.div>
              </div>
            </div>
            <div className="gkt-aboutus-topwhite-overlay"></div>
            <div className="gkt-aboutus-bottomwhite-overlay"></div>
          </div>
        </div>
      </motion.section>
      <section className="gkt-aboutus-detil-wrapper">
        <div className="gkt-aboutus-detil-box">
          <div className="gkt-aboutus-detilgrid-box">
            <div className="gkt-main-aboutus-detil-sec">
              <div
                className="gkt-main-aboutus-detil-heding"
                style={
                  trigger
                    ? { transform: `translateY(0px)`, opacity: 1 }
                    : { transform: `translateY(100px)`, opacity: 0 }
                }
              >
                <h1>about us</h1>
              </div>
              <div className="gkt-aboutus-detales-box">
                <div
                  className="gkt-main-aboutus-detil-img"
                  style={
                    trigger
                      ? { transform: `translateX(0px)`, opacity: 1 }
                      : { transform: `translateX(-100px)`, opacity: 0 }
                  }
                ></div>
                <div className="gkt-main-aboutus-detil-detailes">
                  <p
                    style={
                      trigger
                        ? { transform: `translateX(0px)`, opacity: 1 }
                        : { transform: `translateX(70px)`, opacity: 0 }
                    }
                  >
                    Gopal Krishna Tea Estate is sprawled over an area of 1000
                    acres and is located in the premium tea quality belt of
                    Assam, India and produces around 1 million kg of tea
                    annually. We produce premium quality black CTC Teas and
                    market them in both loose and packaged forms.
                  </p>
                  <p
                    style={
                      trigger
                        ? { transform: `translateX(0px)`, opacity: 1 }
                        : { transform: `translateX(80px)`, opacity: 0 }
                    }
                  >
                    We are considered to be one of the most hygienic and
                    well-equipped tea manufacturing Tea Estate and produce
                    quality tea in the most hygienic conditions..
                    <br />
                    We are a Certified as Trust Tea.
                  </p>
                  <p
                    style={
                      trigger
                        ? { transform: `translateX(0px)`, opacity: 1 }
                        : { transform: `translateX(90px)`, opacity: 0 }
                    }
                  >
                    The tea produced by the us is of superior quality Assam CTC
                    and Orthodox teas (marketed in India through auctions and
                    sold in packets).
                  </p>
                  <p
                    style={
                      trigger
                        ? { transform: `translateX(0px)`, opacity: 1 }
                        : { transform: `translateX(100px)`, opacity: 0 }
                    }
                  >
                    The Company has also launched another brand in the premium
                    segment namely, GKT Premium in Assam.
                  </p>
                  <p
                    style={
                      trigger
                        ? { transform: `translateX(0px)`, opacity: 1 }
                        : { transform: `translateX(110px)`, opacity: 0 }
                    }
                  >
                    The Company has always taken care to produce good quality
                    teas at the lowest possible cost by being a progressive,
                    quality-focused tea producer for over five decades with an
                    advantageous location of its estates in the quality belt of
                    Assam. In order to retain the freshness of the tea leaves,
                    special attention is also given to the packaging.
                  </p>
                  <p
                    style={
                      trigger
                        ? { transform: `translateX(0px)`, opacity: 1 }
                        : { transform: `translateX(120px)`, opacity: 0 }
                    }
                  >
                    Our goal is Quality leadership and Customer Satisfaction
                    actively involving everybody concerned with the commitment
                    towards continual improvement of product quality and
                    effectiveness of system so that satisfaction and confidence
                    of the customers are enhanced.
                  </p>
                </div>
              </div>
              <br />
              <br />
              <div
                className="gkt-aboutus-detales-box gkt-aboutus-mision"
                style={triggersleepy ? { opacity: 1 } : { opacity: 0 }}
              >
                <div
                  className="gkt-aboutus-detales-box-inner"
                  style={
                    triggersleepy
                      ? { transform: `translateX(0px)`, opacity: 1 }
                      : { transform: `translateX(80px)`, opacity: 0 }
                  }
                >
                  <h1>Vision</h1>
                  <p>
                    An India tea industry provides high quality and safe tea for
                    consumers, good livelihoods for producers, workers and their
                    families, whilst at all times caring for the natural
                    environment.
                  </p>
                </div>
                <div
                  className="gkt-aboutus-detales-box-inner"
                  style={
                    triggersleepy
                      ? { transform: `translateX(0px)`, opacity: 1 }
                      : { transform: `translateX(150px)`, opacity: 0 }
                  }
                >
                  <h1>Mission</h1>
                  <p>
                    With over 35 years of experience, we believe to produce
                    market superior quality, innovative and reliable kitchenware
                    products at competitive prices so that every family can
                    afford them.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About_us;
