import React, { useState, useEffect, useRef } from "react";
import cloud from "../images/cloud.webp";
import cloud1 from "../images/cloud2.webp";

import leafblur1 from "../images/liaf-blur1.webp";
import leafblur2 from "../images/liaf-blur2.webp";
import leafblur3 from "../images/liaf-blur3.webp";
import leafblur4 from "../images/liaf-blur4.webp";
import leafblur6 from "../images/liaf-blur6.webp";
import leafgrp3 from "../images/leaf-grp3.webp";
import leafgrp2 from "../images/leaf-grp2.webp";
import Footer from "./footer";
//import Iframe from "react-iframe";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import "./contact.css";
function Contact() {
  const form = useRef();
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const sendEmail = (e) => {
    if (
      document.getElementById("name").value != "" &&
      document.getElementById("email").value != "" &&
      document.getElementById("phone").value != "" &&
      document.getElementById("subject").value != "" &&
      document.getElementById("msg").value != ""
    ) {
      document.getElementById("loddingofbutton").style.display = "block";
      document.getElementById("sendbutton").style.display = "none";
      e.preventDefault();

      emailjs
        .sendForm(
          "service_noh0qzl",
          "template_43lvi2p",
          form.current,
          "MWkjh0rRTV6YTvL_X"
        )
        .then(
          (result) => {
            console.log(result.text);
            document.getElementById("name").value = "";
            document.getElementById("email").value = "";
            document.getElementById("phone").value = "";
            document.getElementById("subject").value = "";
            document.getElementById("msg").value = "";
            document.getElementById("unsuccessmsg").style.display = "none";
            document.getElementById("emptyfuild").style.display = "none";
            document.getElementById("suceesmsg").style.display = "block";
            document.getElementById("loddingofbutton").style.display = "none";
            document.getElementById("sendbutton").style.display = "block";
          },
          (error) => {
            console.log(error.text);
            document.getElementById("suceesmsg").style.display = "none";
            document.getElementById("emptyfuild").style.display = "none";
            document.getElementById("unsuccessmsg").style.display = "block";
            document.getElementById("loddingofbutton").style.display = "none";
            document.getElementById("sendbutton").style.display = "block";
          }
        );
    } else {
      e.preventDefault();
      document.getElementById("suceesmsg").style.display = "none";
      document.getElementById("unsuccessmsg").style.display = "none";
      document.getElementById("emptyfuild").style.display = "block";
      return false;
    }
  };
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  const backremove = {
    hidden: { opacity: 1 },
    show: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 0.5,
      },
    },
  };
  const items = {
    hidden: { opacity: 0, x: 200 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1.6,
      },
    },
  };
  const rightcloud = {
    hidden: { x: 200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const leftcloud = {
    hidden: { x: -200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const cloudmain = {
    hidden: { y: 300 },
    show: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const cloudmaintop = {
    hidden: { y: -300, rotate: 180 },
    show: {
      y: 0,
      rotate: 180,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const leaftop = {
    hidden: { opacity: 0, y: -200 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  return (
    <>
      <motion.section
        className="gkt-contect-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="overlayofherosection">
          <motion.div
            className="backgroundwhitecolor"
            variants={backremove}
          ></motion.div>
        </div>
        <div className="gkt-contact-container">
          <div className="gkt-map-container">
            <div className="gkt-contact-cloud-overlay">
              <motion.div className="cloudegrpbottom" variants={cloudmain}>
                <img
                  src={cloud}
                  style={{
                    transform: `translateY(-${offsetY * 0.2}px) `,
                  }}
                />
              </motion.div>
              <motion.div className="cloudegrpbottom1" variants={cloudmaintop}>
                <img src={cloud} />
              </motion.div>
              <motion.div className="solocloud" variants={rightcloud}>
                <img
                  src={cloud1}
                  style={{
                    transform: `translateX(${offsetY * 0.5}px) `,
                  }}
                />
              </motion.div>
              <motion.div className="solocloud1" variants={leftcloud}>
                <img
                  src={cloud1}
                  style={{
                    transform: `translateX(-${offsetY * 0.2}px) `,
                  }}
                />
              </motion.div>
            </div>
            <div className="gkt-contact-text-continer">
              <div className="gkt-contact-text">
                <motion.div variants={items}>
                  <h1
                    className="gkt-contact-h1text"
                    style={{ transform: `translateY(${offsetY * 0.4}px)` }}
                  >
                    Contact
                  </h1>
                </motion.div>
                <motion.div className="for-horiline" variants={items}>
                  <h1
                    className="gkt-contact-h1text"
                    style={{ transform: `translateY(${offsetY * 0.4}px)` }}
                  >
                    us
                  </h1>
                  <div
                    className="gkt-horizontal-line"
                    style={{ transform: `translateY(${offsetY * 0.4}px)` }}
                  ></div>
                </motion.div>
                <motion.div variants={items}>
                  <p style={{ transform: `translateY(${offsetY * 0.4}px)` }}>
                    Contact us for any business enquiries
                  </p>
                </motion.div>
                <motion.div variants={items}>
                  <h4 style={{ transform: `translateY(-${offsetY * 0.8}px)` }}>
                    Head Office
                  </h4>
                  <p
                    className="gkt-address-detailes"
                    style={{ transform: `translateY(-${offsetY * 0.8}px)` }}
                  >
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    &nbsp; Gopal Krishna Tea Co. Pvt. Ltd., Marwary Patty,
                    Jorhat - 785001, Assam, India
                  </p>
                  <div style={{ transform: `translateY(-${offsetY * 0.8}px)` }}>
                    <a
                      href="mailto:info@gopalkrishnatea.com"
                      className="gkt-address-detailes"
                    >
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                      &nbsp; info@gopalkrishnatea.com
                    </a>
                    <br />
                    <a
                      href="tel:+91 376 2321561"
                      className="gkt-address-detailes"
                    >
                      <i className="fa fa-phone-square" aria-hidden="true"></i>
                      &nbsp; +91 376 2321561
                    </a>{" "}
                    <br />
                    <a
                      className="gkt-address-detailes"
                      href="https://goo.gl/maps/MGp98zE67XTc9RKr6"
                      target="_blanck"
                    >
                      <i
                        className="fa fa-location-arrow"
                        aria-hidden="true"
                      ></i>
                      &nbsp; location
                    </a>
                  </div>
                </motion.div>
              </div>
            </div>

            <div className="gkt-tealeaf-container">
              <div className="gkt-leaf">
                <motion.div
                  className="aboutuslead position-absolute conleafblur6"
                  variants={leaftop}
                >
                  <img
                    src={leafblur6}
                    style={{
                      transform: `rotate(${offsetY * 0.08}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute colleafblur1"
                  variants={leaftop}
                >
                  <img
                    src={leafblur1}
                    style={{
                      transform: `rotate(${offsetY * 0.05}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute colleafblur11"
                  variants={leaftop}
                >
                  <img
                    src={leafblur1}
                    style={{
                      transform: `translateY(-${offsetY * 0.2}px) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafblur3"
                  variants={leaftop}
                >
                  <img
                    src={leafblur3}
                    style={{
                      transform: `rotate(${offsetY * 0.1}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafblur4"
                  variants={leaftop}
                >
                  <img
                    src={leafblur4}
                    style={{
                      transform: `rotate(${offsetY * 0.03}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute conleafgrp3"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp3}
                    style={{
                      transform: `rotate(${offsetY * 0.1}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafgrp31"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp3}
                    style={{
                      transform: `translateY(${offsetY * 0.2}px) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafgrp2"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp2}
                    style={{
                      transform: `translateY(-${offsetY * 0.1}px) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafblur31"
                  variants={leaftop}
                >
                  <img
                    src={leafblur3}
                    style={{
                      transform: `rotate(${offsetY * 0.06}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute leafblur2"
                  variants={leaftop}
                >
                  <img
                    src={leafblur2}
                    className="leafblur2"
                    style={{
                      transform: `rotate(${offsetY * 0.04}deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute conleafgrp21"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp2}
                    style={{
                      transform: `translateY(${
                        offsetY * 0.5
                      }px) rotate(174deg)`,
                    }}
                  />
                </motion.div>
              </div>
            </div>
            <div className="gkt-contact-topwhite-overlay"></div>
            <div className="gkt-contact-bottomwhite-overlay"></div>

            {/*   <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.466398618209!2d92.22284655160537!3d26.1201153503427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x375ac61900000001%3A0xb5578a2cc184e79c!2sGopal%20Krishna%20Tea%20Estate!5e1!3m2!1sen!2sin!4v1645689694892!5m2!1sen!2sin"
              id="myId"
              className="mapwithback"
              display="initial"
              position="absolute"
              loading="lazy"
         />*/}
          </div>
        </div>
      </motion.section>
      <section className="gkt-send-wrapper">
        <div className="gkt-sendcontact-box">
          <div className="gkt-sandcontactgrid-box">
            <div className="gkt-main-sendcontact-sec">
              <div className="gkt-main-sendcontact-heding">
                <h1>get in touch</h1>
              </div>

              <div className="gkt-sendsetailes">
                <div className="gkt-main-sendcontact-detailes">
                  <div>
                    <h4>Kolkata Office</h4>
                    <p>
                      410, City Centre <br /> 19, Synagogue Street <br />
                      Kolkata – 700001 <br />
                      Tel: 033 22434811
                    </p>
                  </div>
                  <div>
                    <h4>Guwahati Office</h4>
                    <p>
                      Meena Bhawan, Kanchan Road
                      <br />
                      Opp. Bora Service, 4th Floor
                      <br />
                      Guwahati – 781007
                    </p>
                  </div>
                </div>
                <div className="gkt-main-sendcontact-send">
                  <form ref={form} onSubmit={sendEmail}>
                    <div className="gkt-end-inner">
                      <div className="gkt-send-innner-inner">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          className="inputtag-sendcontect"
                        />
                      </div>
                      <div className="gkt-send-innner-inner">
                        <label htmlFor="email">E-mail</label>
                        <input
                          type="text"
                          name="email"
                          id="email"
                          className="inputtag-sendcontect"
                        />
                      </div>
                    </div>
                    <div className="gkt-end-inner">
                      <div className="gkt-send-innner-inner">
                        <label htmlFor="phone">Mobile number</label>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          className="inputtag-sendcontect"
                        />
                      </div>
                      <div className="gkt-send-innner-inner">
                        <label htmlFor="subject">Subject</label>
                        <input
                          type="text"
                          name="subject"
                          id="subject"
                          className="inputtag-sendcontect"
                        />
                      </div>
                    </div>
                    <div className="gkt-end-inner">
                      <div
                        className="gkt-send-innner-inner"
                        style={{ width: "100%" }}
                      >
                        <label htmlFor="msg">Massage</label>
                        <textarea
                          name="msg"
                          rows="5"
                          id="msg"
                          className="inputtag-sendcontect"
                        ></textarea>
                      </div>
                    </div>
                    <div style={{ float: "left ", width: "80%" }}>
                      <p className="suceesmsg" id="suceesmsg">
                        Your Message is sent Successfully
                      </p>
                      <p className="unsuccessmsg" id="unsuccessmsg">
                        Something went wrong, your message isn't sent. Try
                        again!!!
                      </p>
                      <p className="unsuccessmsg" id="emptyfuild">
                        You have empty fields, fill them first.
                      </p>
                    </div>
                    <div style={{ float: "right ", width: "20%" }}>
                      <button
                        type="submit"
                        className="gkt-sendcontact-btn"
                        style={{ float: "right " }}
                      >
                        <span className="sendbutton" id="sendbutton">
                          Send
                        </span>
                        <i
                          className="fa fa-spinner loddingofbutton"
                          id="loddingofbutton"
                        ></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="gkt-tealeaf-container-sand">
                <div className="gkt-leaf-sand">
                  <img
                    src={leafblur6}
                    className="position-absolute consendleafblur6"
                    style={{
                      transform: `translateY(${offsetY * 0.2}px) rotate(85deg)`,
                    }}
                  />
                  <img
                    src={leafblur1}
                    className="position-absolute colsandleafblur1"
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.2
                      }px) rotate(183deg)`,
                    }}
                  />
                  <img
                    src={leafblur1}
                    className="position-absolute colsandleafblur11"
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.2
                      }px) rotate(270deg)`,
                    }}
                  />
                  <img
                    src={leafblur3}
                    className="position-absolute colsandleafblur3"
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.1
                      }px) rotate(270deg)`,
                    }}
                  />
                  <img
                    src={leafblur4}
                    className="position-absolute colsandleafblur4"
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.1
                      }px) rotate(321deg)`,
                    }}
                  />

                  <img
                    src={leafgrp3}
                    className="position-absolute colsandleafgrp31"
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.1
                      }px) rotate(272deg)`,
                    }}
                  />
                  <img
                    src={leafgrp2}
                    className="position-absolute colsandleafgrp2"
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.2
                      }px) rotate(258deg)`,
                    }}
                  />
                  <img
                    src={leafblur3}
                    className="position-absolute colsandleafblur31"
                    style={{
                      transform: `translateY(${
                        offsetY * 0.3
                      }px) rotate(121deg)`,
                    }}
                  />
                  <img
                    src={leafblur2}
                    className=" position-absolute colsandleafblur2"
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.3
                      }px) rotate(151deg)`,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Contact;
