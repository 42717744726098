import React, { useState, useEffect } from "react";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./product.css";

import { HashLink } from "react-router-hash-link";

import tea1 from "../images/orthodocs.webp";
import tea2 from "../images/asamtea.webp";
import tea3 from "../images/redtea.webp";
import tea4 from "../images/greetea.webp";
import tea5 from "../images/Lemon&Honey.webp";
import tea6 from "../images/blacktea.webp";
import tea7 from "../images/kesar1.webp";
import tea8 from "../images/masala1.webp";
import tea9 from "../images/assamteapouch.webp";
import tea10 from "../images/premiumtea.webp";
import tea11 from "../images/assamteagold.webp";

import leafblur6 from "../images/liaf-blur6.webp";
import leafblur1 from "../images/liaf-blur1.webp";
import leafgrp1 from "../images/leaf-grp5.webp";
import leafgrp4 from "../images/leaf-grp4.webp";
import leafgrp2 from "../images/leaf-grp2.webp";
import leafblur3 from "../images/liaf-blur3.webp";
import leafgrp3 from "../images/leaf-grp3.webp";
import leafblur5 from "../images/liaf-blur5.webp";

const data = [
  {
    id: 1,
    teaname: "orthodox long leaf tea",
    weight: "250 gm",
    image: tea1,
    url: "/OrthodoxTea",
  },
  {
    id: 2,
    teaname: "assam tea with elaichi",
    weight: "250 gm",
    image: tea2,
    url: "/AssamTeaWithElaichi",
  },
  {
    id: 3,
    teaname: "assam gold",
    weight: "250 gm",
    image: tea3,
    url: "/AssamGold",
  },
  {
    id: 4,
    teaname: "assam tea with kesar",
    weight: "250 gm",
    image: tea7,
    url: "/AssamTeaWithKesar",
  },
  {
    id: 5,
    teaname: "masala tea",
    weight: "250 gm",
    image: tea8,
    url: "/MasalaTea",
  },
  {
    id: 6,
    teaname: "green tea",
    weight: "25 bags",
    image: tea4,
    url: "/GreenTea",
  },
  {
    id: 7,
    teaname: "Lemon & Honey tea",
    weight: "25 bags",
    image: tea5,
    url: "/GreenTealemonnhoney",
  },
  {
    id: 8,
    teaname: "black tea",
    weight: "25 bags",
    image: tea6,
    url: "/BlackTea",
  },
  {
    id: 9,
    teaname: "Assam tea",
    weight: "250 gm",
    image: tea9,
    url: "/AssamTea",
  },
  {
    id: 10,
    teaname: "premium quality Assam tea",
    weight: "250 gm",
    image: tea10,
    url: "/PremiumQualityAssamTea",
  },
  {
    id: 11,
    teaname: "Assam tea Gold with long leaves",
    weight: "250 gm",
    image: tea11,
    url: "/AssamTeaGold",
  },
];
function Product() {
  const [offsetY, setOffsetY] = useState(0);
  const [trigger, settrigger] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 300) {
      settrigger(true);
    }
    if (window.scrollY < 300) {
      settrigger(false);
    }

    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <div id="Product" className="gkt-product-wrapper">
        <div className="gkt-product-box">
          <div className="gkt-productgrid-box">
            <div
              className="gkt-side-navigation"
              style={
                trigger
                  ? { transform: `translateY(0px)`, opacity: 1 }
                  : { transform: `translateY(50px)`, opacity: 0 }
              }
            >
              <p>
                <HashLink smooth to="/#top" className="side-link">
                  Home
                </HashLink>
              </p>
              <div className="gkt-varticle-line"></div>
              <p>
                <HashLink smooth to="/#About" className="side-link">
                  about us
                </HashLink>
              </p>
              <p>
                <HashLink smooth to="/#Benefits" className="side-link">
                  benefits
                </HashLink>
              </p>
              <p>
                <HashLink smooth to="/Contact" className="side-link">
                  contact
                </HashLink>
              </p>
            </div>
            <div className="gkt-main-product-sec">
              <div className="gkt-main-product-heding">
                <h1
                  style={
                    trigger
                      ? { transform: `translateY(0px)`, opacity: 1 }
                      : { transform: `translateY(150px)`, opacity: 0 }
                  }
                >
                  gopal krishna tea
                </h1>
              </div>
              <div className="gkt-big-hedding">
                <h1 style={{ transform: `translateY(${offsetY * 0.2}px)` }}>
                  assam tea
                </h1>
              </div>
              <div className="gkt-main-product-slider">
                <Swiper
                  modules={[Navigation, A11y]}
                  spaceBetween={50}
                  slidesPerView={4}
                  navigation
                  breakpoints={{
                    // when window width is >= 640px
                    200: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    830: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                  }}
                >
                  {data.map((tea) => (
                    <SwiperSlide
                      className="slide"
                      key={tea.id}
                      style={{ transform: `translateY(-${offsetY * 0.25}px)` }}
                    >
                      <HashLink smooth to={tea.url} className="side-link">
                        <div className="gkt-carosel-container">
                          <div className="gkt-carosel-imageboc">
                            <img src={tea.image} />
                          </div>
                          <div className="gkt-carosel-detailesbox">
                            <h4>{tea.teaname}</h4>
                            <p>{tea.weight}</p>
                          </div>
                        </div>
                      </HashLink>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div className="gkt-protealeaf-container">
          <div className="gkt-proleaf">
            <img
              src={leafblur6}
              className="position-absolute proleafblur6"
              style={{
                transform: `rotate(${offsetY * 0.02}deg) `,
              }}
            />
            <img
              src={leafblur1}
              className="position-absolute proleafblur1"
              style={{
                transform: `rotate(${offsetY * 0.08}deg) `,
              }}
            />
            <img
              src={leafgrp1}
              className="position-absolute proleafgrp1"
              style={{
                transform: `translateY(${offsetY * 0.08}%) `,
              }}
            />
            <img
              src={leafgrp4}
              className="position-absolute proleafgrp4"
              style={{
                transform: `translateY(${offsetY * 0.08}%) `,
              }}
            />
            <img
              src={leafgrp2}
              className="position-absolute proleafgrp2"
              style={{
                transform: `translateY(${offsetY * 0.08}%) rotate(100deg)`,
              }}
            />
            <img src={leafgrp1} className="position-absolute proleafgrp11" />
            <img
              src={leafblur3}
              className="position-absolute proleafblur3"
              style={{
                transform: `rotate(${offsetY * 0.01}deg) `,
              }}
            />
            <img
              src={leafgrp3}
              className="position-absolute proleafgrp3"
              style={{
                transform: `translateY(${offsetY * 0.02}%) `,
              }}
            />
            <img
              src={leafgrp3}
              className="position-absolute proleafgrp31"
              style={{
                transform: `translateY(${offsetY * 0.03}%) rotate(80deg)`,
              }}
            />
            <img
              src={leafgrp3}
              className="position-absolute proleafgrp32"
              style={{
                transform: `translateY(${offsetY * 0.08}%) rotate(0deg)`,
              }}
            />
            <img
              src={leafblur6}
              className="position-absolute proleafblur61"
              style={{
                transform: `rotate(${offsetY * 0.05}deg) `,
              }}
            />
            <img
              src={leafgrp4}
              className="position-absolute proleafgrp41"
              style={{
                transform: `translateY(-${offsetY * 0.08}%) rotate(234deg)`,
              }}
            />
            <img
              src={leafblur5}
              className="position-absolute proleafblur5"
              style={{
                transform: `translateY(-${offsetY * 0.001}%) rotate(189deg) `,
              }}
            />
            <img
              src={leafgrp1}
              className="position-absolute proleafgrp12"
              style={{
                transform: `translateY(-${offsetY * 0.08}%) rotate(189deg)`,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
