import React, { useState, useEffect } from "react";
import "./tea.css";
import Footer from "./footer";
import Product_tea from "./product_tea";

import teacloud from "../images/teacloud.webp";
import cloud1 from "../images/cloud2.webp";

import herotea from "../images/assamteagold.webp";
import heroteacup from "../images/assamteagold4.webp";
import sleepybag from "../images/assamteagold1.webp";
import towpagetea from "../images/assamteagold3.webp";

import galdenicon from "../images/galden-icon.png";
import cupicon from "../images/cup-icon.png";
import lovegkticon from "../images/lovegkt-icon.png";

import leafblur1 from "../images/liaf-blur1.webp";
import leafblur3 from "../images/liaf-blur3.webp";
import leafblur5 from "../images/liaf-blur5.webp";
import leafblur4 from "../images/liaf-blur4.webp";
import leafgrp4 from "../images/leaf-grp4.webp";
import leafgrp1 from "../images/leaf-grp1.webp";
import leafgrp2 from "../images/leaf-grp2.webp";
import leafgrp3 from "../images/leaf-grp3.webp";
import liaf1 from "../images/assamteagold2.webp";
import { motion } from "framer-motion";
function AssamTeaGold() {
  const [offsetY, setOffsetY] = useState(0);
  const [trigger, settrigger] = useState(false);
  const [triggersleepy, settriggersleepy] = useState(false);
  const [buybtn, setbuybtn] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 30) {
      setbuybtn(true);
    }
    if (window.scrollY < 30) {
      setbuybtn(false);
    }
    if (window.scrollY > 300) {
      settrigger(true);
    }
    if (window.scrollY < 300) {
      settrigger(false);
    }
    if (window.scrollY > 900) {
      settriggersleepy(true);
    }
    if (window.scrollY < 800) {
      settriggersleepy(false);
    }
    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };
  const backremove = {
    hidden: { opacity: 1 },
    show: {
      opacity: 0,
      transition: {
        ease: "easeInOut",
        duration: 1.6,
      },
    },
  };
  const teabox = {
    hidden: { y: -300 },
    show: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const teacup = {
    hidden: { y: 100 },
    show: {
      y: 0,
      transition: {
        ease: [0.6, 0.01, -0.05, 0.95],
        duration: 1,
      },
    },
  };
  const rightcloud = {
    hidden: { x: 200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const leftcloud = {
    hidden: { x: -200 },
    show: {
      x: 0,
      transition: {
        ease: "easeInOut",
        duration: 1,
      },
    },
  };
  const leaftop = {
    hidden: { opacity: 0, y: -200 },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  };
  return (
    <>
      <motion.section
        className="gkt-tea-wrapper"
        variants={container}
        initial="hidden"
        animate="show"
      >
        <div className="overlayofproductsection">
          <motion.div
            className="backgroundwhitecolor"
            variants={backremove}
          ></motion.div>
        </div>
        <div className="gkt-tea-container">
          <div className="gkt-teaimage-container">
            <div className="gkt-product-item-grid">
              <div className="gkt-teagrid-inner">
                <div className="gkt-product-2box-set">
                  <motion.div variants={teabox}>
                    <img
                      src={herotea}
                      className="redtea"
                      style={{
                        transform: `translateY(-${offsetY * 0.2}px) `,
                      }}
                    />
                  </motion.div>
                </div>
                <div className="gkt-product-box-set">
                  <motion.div variants={teabox}>
                    <img
                      src={herotea}
                      className="redtea"
                      style={{
                        transform: `translateY(-${offsetY * 0.2}px) `,
                      }}
                    />
                  </motion.div>
                </div>
                <div className="gkt-tea-cup-set">
                  <motion.div variants={teacup}>
                    <img
                      src={heroteacup}
                      className="teacupred"
                      style={{
                        transform: `translateY(-${offsetY * 0.1}px) `,
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            </div>
            <div className="gkt-teacloud-overlay">
              <motion.div className="cloudegrpbottom" variants={teacup}>
                <img
                  src={teacloud}
                  style={{
                    transform: `translateY(-${offsetY * 0.3}px) `,
                  }}
                />
              </motion.div>
              <motion.div className="solocloud" variants={leftcloud}>
                <img
                  src={cloud1}
                  style={{
                    transform: `translateX(${offsetY * 0.5}px) `,
                  }}
                />
              </motion.div>
              <motion.div className="solocloud1" variants={rightcloud}>
                <img
                  src={cloud1}
                  style={{
                    transform: `translateX(-${offsetY * 0.2}px) scaleX(-1)`,
                  }}
                />
              </motion.div>
            </div>
            <div className="gkt-teatealeaf-container">
              <div className="gkt-tealeaf">
                <motion.div
                  className="aboutuslead position-absolute tealeafblur1"
                  variants={leaftop}
                >
                  <img
                    src={leafblur1}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.3
                      }px) rotate(45deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafblur3"
                  variants={leaftop}
                >
                  <img
                    src={leafblur3}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.2
                      }px) rotate(105deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafblur31"
                  variants={leaftop}
                >
                  <img
                    src={leafblur3}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.3
                      }px) rotate(119deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafblur5"
                  variants={leaftop}
                >
                  <img
                    src={leafblur5}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.2
                      }px) rotate(206deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafblur4"
                  variants={leaftop}
                >
                  <img
                    src={leafblur4}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.3
                      }px) rotate(386deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafgrp4"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp4}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.2
                      }px) rotate(422deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafblur11"
                  variants={leaftop}
                >
                  <img
                    src={leafblur1}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.3
                      }px) rotate(288deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafgrp1"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp1}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.2
                      }px) rotate(125deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafgrp2"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp2}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.3
                      }px) rotate(286deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafgrp3"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp3}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.2
                      }px) rotate(176deg) `,
                    }}
                  />
                </motion.div>
                <motion.div
                  className="aboutuslead position-absolute tealeafgrp41"
                  variants={leaftop}
                >
                  <img
                    src={leafgrp4}
                    style={{
                      transform: `translateY(-${
                        offsetY * 0.3
                      }px) rotate(197deg) `,
                    }}
                  />
                </motion.div>
              </div>
            </div>
            <div className="gkt-teatopwhite-overlay"></div>
            <div className="gkt-teabottomwhite-overlay"></div>
            <div className="gkt-teabottomwhite-overlay"></div>
            <div className="gkt-teabottomwhite-overlay"></div>
          </div>
        </div>
      </motion.section>
      <section className="gkt-3tea-wrapper">
        <a
          className="buynowbtn "
          style={
            buybtn
              ? { transform: `translateX(0px)`, opacity: 1 }
              : { transform: `translateX(80px)`, opacity: 0 }
          }
        >
          Buy now
        </a>
        <div className="gkt-tea-container">
          <div className="gkt-3tea-container">
            <div
              className="gkt-box-area"
              style={
                trigger
                  ? { transform: `translateX(0px)`, opacity: 1 }
                  : { transform: `translateX(-100px)`, opacity: 0 }
              }
            >
              <img src={towpagetea} className="gkt-3tea-image" />
            </div>
            <div className="gkt-detailes-area">
              <h2
                style={
                  trigger
                    ? { transform: `translateX(0px)`, opacity: 1 }
                    : { transform: `translateX(80px)`, opacity: 0 }
                }
              >
                Finest ingredients
              </h2>
              <p
                className="detaile-area-p"
                style={
                  trigger
                    ? {
                        transform: `translateX(0px)`,
                        opacity: 1,
                      }
                    : { transform: `translateX(90px)`, opacity: 0 }
                }
              >
                assam tea CTC with long leaves gold
              </p>
              <div
                className="gkt-tea2horizontal-line"
                style={
                  trigger
                    ? { transform: `translateX(0px)`, opacity: 1 }
                    : { transform: `translateX(100px)`, opacity: 0 }
                }
              ></div>
              <div
                className="gkt-tea2horizontal-line-inner"
                style={
                  trigger
                    ? { transform: `translateX(0px)`, opacity: 1 }
                    : { transform: `translateX(120px)`, opacity: 0 }
                }
              >
                <div className="gkt-tea2horizontal-line-inner-img">
                  <img src={galdenicon} />
                </div>
                <div className="gkt-tea2horizontal-line-inner-detailes">
                  <h4>straight from the garden</h4>
                  <p>
                    We proudly present one of the best quality Assam Tea made
                    with special care to give you the best flavor of Assam's
                    garden.
                  </p>
                </div>
              </div>
              <div
                className="gkt-tea2horizontal-line-inner"
                style={
                  trigger
                    ? { transform: `translateX(0px)`, opacity: 1 }
                    : { transform: `translateX(140px)`, opacity: 0 }
                }
              >
                <div className="gkt-tea2horizontal-line-inner-img">
                  <img src={cupicon} />
                </div>
                <div className="gkt-tea2horizontal-line-inner-detailes">
                  <h4>tea ingrediants</h4>
                  <p>
                    finest hand-picked long tea leaf with world-class process
                    gives heart touching sip of tea.
                  </p>
                </div>
              </div>
              <div
                className="gkt-tea2horizontal-line-inner"
                style={
                  trigger
                    ? { transform: `translateX(0px)`, opacity: 1 }
                    : { transform: `translateX(160px)`, opacity: 0 }
                }
              >
                <div className="gkt-tea2horizontal-line-inner-img">
                  <img src={lovegkticon} />
                </div>
                <div className="gkt-tea2horizontal-line-inner-detailes">
                  <h4>health benefits</h4>
                  <p>
                    our tea has a plethora of benefits like Rich in
                    Antioxidants, Boosts Energy, fight with obesity etc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gkt-2tea-wrapper">
        <div className="gkt-tea-container">
          <div className="gkt-2tea-container">
            <div className="gkt-box-area">
              <img
                src={sleepybag}
                className="gkt-2tea-image"
                style={
                  triggersleepy
                    ? { transform: `scale(1) rotate(125deg)` }
                    : { transform: `scale(2) rotate(125deg)` }
                }
              />
            </div>
            <div className="gkt-bogteatealeaf-container">
              <div className="gkt-tealeaf">
                <img src={liaf1} className="position-absolute teapouch" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Product_tea />
      <Footer />
    </>
  );
}

export default AssamTeaGold;
