import React from "react";
import { HashLink } from "react-router-hash-link";
import pagenotfound from "../images/pagenotfound.png";
import "./PageNotFound.css";

function PageNotFound() {
  return (
    <>
      <div className="gkt-404-wrapper">
        <div className="gkt-dilaies-404">
          <div className="gkt-image-box">
            <img src={pagenotfound} />
          </div>
          <div className="gkt-text-box">
            <h1>oooooooops! page not found</h1>
            <p>Sorry, the page you're looking for doesn't exist.</p>
            <HashLink smooth to="/" className="auout_usbtn">
              Go back
            </HashLink>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageNotFound;
