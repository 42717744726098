import React, { useState, useEffect } from "react";
import { Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./product.css";

import { HashLink } from "react-router-hash-link";

import tea1 from "../images/orthodocs.webp";
import tea2 from "../images/asamtea.webp";
import tea3 from "../images/redtea.webp";
import tea4 from "../images/greetea.webp";
import tea5 from "../images/Lemon&Honey.webp";
import tea6 from "../images/blacktea.webp";
import tea7 from "../images/kesar1.webp";
import tea8 from "../images/masala1.webp";
import tea9 from "../images/assamteapouch.webp";
import tea10 from "../images/premiumtea.webp";
import tea11 from "../images/assamteagold.webp";

const data = [
  {
    id: 1,
    teaname: "orthodox long leaf tea",
    weight: "250 gm",
    image: tea1,
    url: "/OrthodoxTea",
  },
  {
    id: 2,
    teaname: "assam tea with elaichi",
    weight: "250 gm",
    image: tea2,
    url: "/AssamTeaWithElaichi",
  },
  {
    id: 3,
    teaname: "assam gold",
    weight: "250 gm",
    image: tea3,
    url: "/AssamGold",
  },
  {
    id: 4,
    teaname: "assam tea with kesar",
    weight: "250 gm",
    image: tea7,
    url: "/AssamTeaWithKesar",
  },
  {
    id: 5,
    teaname: "masala tea",
    weight: "250 gm",
    image: tea8,
    url: "/MasalaTea",
  },
  {
    id: 6,
    teaname: "green tea",
    weight: "25 bags",
    image: tea4,
    url: "/GreenTea",
  },
  {
    id: 7,
    teaname: "Lemon & Honey tea",
    weight: "25 bags",
    image: tea5,
    url: "/GreenTealemonnhoney",
  },
  {
    id: 8,
    teaname: "black tea",
    weight: "25 bags",
    image: tea6,
    url: "/BlackTea",
  },
  {
    id: 9,
    teaname: "Assam tea",
    weight: "250 gm",
    image: tea9,
    url: "/AssamTea",
  },
  {
    id: 10,
    teaname: "premium quality Assam tea",
    weight: "250 gm",
    image: tea10,
    url: "/PremiumQualityAssamTea",
  },
  {
    id: 11,
    teaname: "Assam tea Gold with long leaves",
    weight: "250 gm",
    image: tea11,
    url: "/AssamTeaGold",
  },
];
function Product_tea() {
  const [offsetY, setOffsetY] = useState(0);
  const [pttrigger, setpttrigger] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 1000 || window.scrollY > 1750) {
      setpttrigger(true);
    }
    if (window.scrollY < 900 && window.scrollY < 1750) {
      setpttrigger(false);
    }

    setOffsetY(window.pageYOffset);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div className="gkt-product-wrapper productteawrapper ">
        <div className="gkt-product-box">
          <div className="gkt-productgrid-box">
            <div className="gkt-main-product-sec gkt-main-product_tea">
              <div className="gkt-main-product-heding">
                <h1
                  style={
                    pttrigger
                      ? { transform: `translateY(0px)`, opacity: 1 }
                      : { transform: `translateY(100px)`, opacity: 0 }
                  }
                >
                  gopal krishna tea
                </h1>
              </div>

              <div
                className="gkt-main-product-slider productpagelist"
                style={
                  pttrigger
                    ? { transform: `translateY(0px)`, opacity: 1 }
                    : { transform: `translateY(100px)`, opacity: 0 }
                }
              >
                <Swiper
                  modules={[Navigation, A11y]}
                  spaceBetween={50}
                  slidesPerView={4}
                  navigation
                  breakpoints={{
                    // when window width is >= 640px
                    200: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    480: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    640: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                  }}
                >
                  {data.map((tea) => (
                    <SwiperSlide className="slide" key={tea.id}>
                      <HashLink smooth to={tea.url} className="side-link">
                        <div className="gkt-carosel-container">
                          <div className="gkt-carosel-imageboc gkt-carosel-forproductpage">
                            <img src={tea.image} />
                          </div>
                          <div className="gkt-carosel-detailesbox">
                            <h4>{tea.teaname}</h4>
                            <p>{tea.weight}</p>
                          </div>
                        </div>
                      </HashLink>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product_tea;
