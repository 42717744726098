import Navbar from "./components/navbar";
import Herosection from "./components/herosection";
import Product from "./components/product";
import Teainfo from "./components/tea_info";
import About from "./components/about";
import Benefits from "./components/benefits";
import Footer from "./components/footer";
import Contact from "./components/contact";
import About_us from "./components/about_us";
import OrthodoxTea from "./components/orthodoxTea";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import ElaichiTea from "./components/elaichiTea";
import KesarTea from "./components/kesarTea";
import MasalaTea from "./components/masalaTea";
import Assamtea from "./components/assamTea";
import Premiumtea from "./components/premiumtea";
import AssamTeaGold from "./components/assamTeaGold";
import GreenTea from "./components/greenTea";
import GreenTealemonnhoney from "./components/greenTealemonnhoney";
import BlackTea from "./components/blackTea";
import PageNotFound from "./components/PageNotFound";
import AssamGold from "./components/AssamGold";
import Brochure from "./components/Brochure";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About_us />} />

          <Route path="/AssamGold" element={<AssamGold />} />
          <Route path="/OrthodoxTea" element={<OrthodoxTea />} />
          <Route path="/AssamTeaWithElaichi" element={<ElaichiTea />} />
          <Route path="/AssamTeaWithKesar" element={<KesarTea />} />
          <Route path="/MasalaTea" element={<MasalaTea />} />
          <Route path="/AssamTea" element={<Assamtea />} />
          <Route path="/PremiumQualityAssamTea" element={<Premiumtea />} />
          <Route path="/AssamTeaGold" element={<AssamTeaGold />} />
          <Route path="/GreenTea" element={<GreenTea />} />
          <Route
            path="/GreenTealemonnhoney"
            element={<GreenTealemonnhoney />}
          />
          <Route path="/BlackTea" element={<BlackTea />} />

          <Route path="/Brochure" element={<Brochure />} />

          <Route path="/" exect element={<Home />} />
          <Route path="*" exect element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

function Home() {
  return (
    <>
      <Herosection />
      <Product />
      <Teainfo />
      <About />
      <Benefits />
      <Footer />
    </>
  );
}

export default App;
